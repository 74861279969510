import { useI18n } from 'vue-i18n';

export const useValidationRules = () => {
  const { t } = useI18n();
  const personalDataRegexp = /^([a-zA-ZА-Яа-яІіЇїЄєҐґЁё -]{2,32})?$/;

  const required = (value: string) => Boolean(value) || t('error_empty_field');
  const passwordMinLength = (value: string) => (value?.length >= 8) || t('error_password_min_length');
  const passwordMaxLength = (value: string) => (value.length <= 32) || t('error_password_max_length');
  const passwordMatched =
    (password: string, passwordConfirm: string) => password === passwordConfirm || t('error_password_match');
  const password = (value: string) =>
    (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z!@#$%*()|^+:;=?'~&\-/\]\[\{\}\(\)<>,.])[a-zA-Z0-9!@#$%*()|^+:;=?'~&\-/\]\[\{\}\(\)<>,.]*$/.test(value)) ||
    t('error_password_required');
  const passwordForbiddenSymbols =
    (value: string) => !(value.indexOf('<') > -1 || value.indexOf('>') > -1) || t('error_password_forbidden_symbols');
  const email = (value: string) => /^[A-Za-z0-9\._-]{1,44}@[A-Za-z0-9\._-]{1,32}\.[A-Za-z0-9\._-]{1,5}$/.test(value)
      || t('error_invalid_email');
  const mfa = (value: string) => /^[\d+]{6}$/.test(value) || t('error_mfa_number_required');
  const bonusCode = (value: string) => /^[a-zA-Z0-9]{5,20}$/.test(value) || t('cabinet_balance_promocode_validation');
  const nickname = (value: string) => /^[0-9a-zA-ZА-Яа-яІіЇїЄєҐґЁё_-]{2,17}$/.test(value) ||
    t('error_invalid_personal_data_nickname');
  const name = (value: string) => personalDataRegexp.test(value) ||
    t('error_invalid_personal_data_field', { fieldName: t('cabinet_personal_data_name') });
  const lastName = (value: string) => personalDataRegexp.test(value) ||
    t('error_invalid_personal_data_field', { fieldName: t('cabinet_personal_data_last_name') });
  const city = (value: string) => /^([a-zA-ZА-Яа-я -]{2,32})?$/.test(value) ||
    t('error_invalid_personal_data_field', { fieldName: t('cabinet_personal_data_city') });
  const requiredCountry = (value: string) => Boolean(value) || t('error_invalid_country');
  const requiredBirthday = (value: string) => Boolean(value.length) || t('error_invalid_birthday');
  const phoneFirstDigit = (value: string) => /^[1-9]\d*$/.test(value) || t('error_invalid_phone');
  const phoneLength = (value: string) => /^[\d+]{9,15}$/.test(value) || t('error_invalid_phone_length');
  const confirmationCode = (value: string) => /^[\d+]{4}$/.test(value) || t('error_invalid_confirmation_code');

  return  {
    required,
    passwordMinLength,
    passwordMaxLength,
    passwordMatched,
    password,
    passwordForbiddenSymbols,
    email,
    mfa,
    bonusCode,
    nickname,
    name,
    lastName,
    requiredCountry,
    city,
    requiredBirthday,
    phoneLength,
    phoneFirstDigit,
    confirmationCode,
  };
};
